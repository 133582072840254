// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'name'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'mini_page',
    span: 12
  }, {
    prop: 'status',
    back: true
  }, {
    prop: 'activity_type'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'begin_date'
  }, {
    prop: 'end_date'
  }, {
    prop: 'activity_time'
  }]
}
